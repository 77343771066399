/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// All components
// import * as components from 'vuetify/components'
// import * as labs from 'vuetify/labs/components'

// Labs
// import { VDatePicker } from 'vuetify/labs/VDatePicker'

// Utils
import colors from 'vuetify/lib/util/colors'

// Adapters
// import { VuetifyDateAdapter } from 'vuetify/labs/date/adapters/vuetify'
// import { CustomAdapter } from '@/plugins/date_adapters/customAdapter'

// Custom icons
import RcxMuniLogo from './../components/customIcons/RcxMuniLogo.vue'
import VueLogo from './../components/customIcons/VueLogo.vue'
import VuetifyLogo from './../components/customIcons/VuetifyLogo.vue'

// locales
import en from './../translations/enExt'
import cs from './../translations/csExt'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  // components: {
  //   VDatePicker
  //   ...components,
  //   ...labs
  // },
  // date: {
  //   adapter: CustomAdapter,
  //   locale: {
  //     enExt: enExt,
  //     csExt: csExt,
  //   },
  // },
  defaults: {
    VTextarea: {
      variant: 'underlined',
      color: 'primary',
    },
    VTextField: {
      variant: 'underlined',
      color: 'primary',
    },
    VSelect: {
      variant: 'underlined',
      color: 'primary',
    },
    VRadio: {
      color: 'primary',
    },
    VRadioGroup: {
      color: 'primary',
    },
    VCheckbox: {
      color: 'primary',
    },
    VFileInput: {
      variant: 'underlined',
      color: 'primary',
    },
    VAutocomplete: {
      variant: 'underlined',
      color: 'primary',
    },
    VCombobox: {
      variant: 'underlined',
      color: 'primary',
    },
    // VCard: {
    //   elevation: 2,
    //   rounded: 0,
    // },
    // VBtn: {
    //   elevation: 2,
    //   rounded: 0,
    // },
    VSwitch: {
      color: 'primary'
    },
    VExpansionPanel: {
      elevation: 2,
      rounded: 0,
    }
  },
  theme: {
    defaultTheme: 'light',
    variations: {
      colors: ['primary', 'secondary', 'tertiary', 'accent', 'error', 'info', 'success', 'warning'],
      lighten: 10,
      darken: 10,
    },
    themes: {
      light: {
        colors: {
          primary: colors.blueGrey.base,
          secondary: '#424242',
          tertiary: colors.purple.accent3,
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          celspac: '#58A0B6',
        },
      },
      dark: {
        colors: {
          primary: colors.blueGrey.base,
          secondary: '#424242',
          tertiary: colors.purple.accent3,
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          celspac: '#58A0B6',
        },
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      RcxMuniLogo: RcxMuniLogo,
      VueLogo: VueLogo,
      VuetifyLogo: VuetifyLogo,
      ErrorIcon: 'mdi-alert-outline'
    }
  },
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { en, cs }
  }
})
