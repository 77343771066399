import { ref } from 'vue'
import { defineStore } from 'pinia'
import {useLocale} from 'vuetify'

export const useAppStore = defineStore('app', () => {
  const appLocale = ref(null)
  const { current } = useLocale()


  function changeAppLocale(lang) {
    appLocale.value = lang
    current.value = lang
    localStorage.setItem('oaf-app-locale', lang)
  }


  return {
    appLocale,
    changeAppLocale
  }
})
