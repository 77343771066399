export const departments = [
    {
        label: 'Central laboratories',
        // description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu.',
        path: 'central_labs',
        isVisible: true,
        services: [
          {
            label: 'Trace Analytical Laboratories',
            path: 'services',
            form: 'services',
            subDepartment: 'tal',
            description: 'Trace Analytical Laboratories provide a service in the field of sampling and analysis of a wide range of organic emergent pollutants, their metabolites, trace elements and species mainly in human and environmental samples. We can design a tailor-made sampling plan for customers and develop and validate new analytical methods.<br><br> <a href="https://www.recetox.muni.cz/en/services/recetox-central-laboratories/trace-analytical-laboratory" target="_blank">https://www.recetox.muni.cz/en/services/recetox-central-laboratories/trace-analytical-laboratory</a>',
            isVisible: true,
          },
          {
            label: 'Biomarker Analytical Laboratories',
            path: 'non_target_screening',
            form: 'non_target_screening',
            subDepartment: 'bal',
            description: 'The Biomarker Analytical Laboratories provides quantitative mass spectrometry assays for metabolite and protein markers and screening of small molecules in biospecimens via high-resolution mass spectrometry.<br><br><a href="https://www.recetox.muni.cz/en/services/recetox-central-laboratories/biomarker-analysis-laboratories" target="_blank">https://www.recetox.muni.cz/en/services/recetox-central-laboratories/biomarker-analysis-laboratories</a>',
            isVisible: true,
          },
          {
            label: 'Microbiome Laboratories',
            path: 'metagenomics',
            form: 'metagenomics',
            subDepartment: 'mgl',
            description: 'The Microbiome Laboratories is a specialized research facility that provides advanced resources and expertise to support the analysis of microbial communities in various biological systems. This facility offers a range of services to researchers, including sample processing, DNA extraction, preparation of sequencing library, and other molecular biological analyses (gene expression, SNPs, ELISA analysis etc.). <br><br> By providing expert support and resources, this facility helps researchers to analyze microbiome data, identify microbial communities, and understand their roles in various biological systems. <br> <a href="https://www.recetox.muni.cz/en/services/recetox-central-laboratories/microbiome-analytical-laboratories" target="_blank">https://www.recetox.muni.cz/en/services/recetox-central-laboratories/microbiome-analytical-laboratories</a> ',
            isVisible: true,
          }
        ],
    },
    {
        label: 'Data Services',
        // description: 'Sed convallis magna eu sem. Phasellus faucibus molestie nisl. Phasellus rhoncus. Duis viverra diam non justo. Etiam commodo dui eget wisi.',
        path: 'data_services',
        isVisible: false,
        services:[
          {
            label: 'Sequence Data Processing and Analysis (bioinformatics)',
            path: 'seq',
            form: 'seq',
            subDepartment: 'sda',
            description: 'This bioinformatics core facility provides advanced computational resources and expertise to support biological research projects. We offer a wide range of bioinformatics analysis pipelines to analyze complex biological data and gain insights into biological systems. <br> Our services cover state-of-the-art development of pipelines and analysis of data from a wide range of omics experiments from microarrays and NGS sequencing, and include genome assembly, metagenomics (WMGS and targeted - 16SrRNA, ITS, …), phylogenetics, genome analysis (targeted panels of mutations, WGS, WES, SNP calling), transcriptome analysis (RNAseq, miRNAseq, expression microarrays), epigenomics (epigenetic chips). <br><br> <a href="https://www.recetox.muni.cz/en/services/data-services-1/sequence-data-processing-and-analysis" target="_blank">https://www.recetox.muni.cz/en/services/data-services-1/sequence-data-processing-and-analysis</a>',
            isVisible: true,
          },
          {
            label:'Biostatistics and Data Analyses',
            path: 'analysis',
            form: 'analysis',
            subDepartment: 'bda',
            description: 'Biostatistics and data analyses core facility provides expertise and resources to support statistical analysis and data management for biological research projects. From clinical data to exposome data, from genomics to metabolomics and from epigenomics to biomedical imaging, this core facility offers integrated data analysis and tailored solutions in biostatistics and biomarker discovery.',
            isVisible: true,
          },
        ]
    },
    {
        label: 'Population studies CELSPAC and biobanking',
        description: 'CELSPAC population studies (e. g. the long-term ELSPAC study or the mother-child TNG study) collect different types of data and biological samples on individual exposure and health, parts of which are made available to scientists and research teams. We also offer consulting, training, and services enabling the design and realization of your population studies, collecting questionnaire data, sampling, immunochemistry analyses and/or biobanking of human biological matrices.',
        path: 'celspac',
        isVisible: true,
        services:[
          {
            label: 'CELSPAC services',
            path: 'lab_services',
            form: 'lab_services',
            subDepartment: 'cls',
            description: 'CELSPAC population studies (e.g. the long-term ELSPAC study or the mother-child TNG study) collect different types of data and biological samples on individual exposure and health, parts of which are made available to scientists and research teams.<br> We also offer consulting, training, and services enabling the design and realization of your population studies, collecting questionnaire data, sampling, immunochemistry analyses and/or biobanking of human biological matrices.',
            isVisible: true,
          },
        ]
    },
    {
        label: 'Other services',
        // description: 'Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at dui. Praesent in mauris eu tortor porttitor accumsan.',
        path: 'other',
        isVisible: true,
        services:[
          {
            label: 'Secondary student internship',
            path: 'student_internship',
            form: 'student_internship',
            description: 'RECETOX RI has maintained a long tradition of cooperating with high schools. For talented high school students we offer the possibility of internship or high school scientific activities (so called SOČ). <br><br> <a href="https://www.recetox.muni.cz/en/cooperation/cooperation-with-high-schools" target="_blank">https://www.recetox.muni.cz/en/cooperation/cooperation-with-high-schools</a>',
            subDepartment: 'other',
            isVisible: true,
          },
          {
            label: 'Specialized laboratories supporting research',
            path: 'specialized_lab',
            form: 'specialized_lab',
            description: 'The capacities of accredited laboratories are complemented by the distributed capacities of specialized laboratories supporting research in the areas of photochemistry, supramolecular chemistry, toxicology, protein engineering, environmental health and environmental chemistry and modelling. These highly specialized facilities significantly strengthen the research infrastructure potential for collaboration with the application sector by supporting the development of new biotechnologies, materials, signaling molecules and test systems. <br><br> <a href="https://www.recetox.muni.cz/en/services/other-services" target="_blank">https://www.recetox.muni.cz/en/services/other-services</a>',
            subDepartment: 'other',
            isVisible: true,
          },
          {
            label: 'Training',
            path: 'training',
            form: 'training',
            description: 'Operators and scientists are trained through courses, workshops and hands-on training sessions. RECETOX RI researchers and laboratory staff have extensive training experience in their research fields. Training is tailored to the customer\'s needs; we emphasise each client\'s specific needs. Training usually occurs at RECETOX RI labs but can also be done at the client\'s facility. <br><br> <a href="https://www.recetox.muni.cz/en/services/recetox-ri" target="_blank">https://www.recetox.muni.cz/en/services/recetox-ri</a>',
            subDepartment: 'other',
            isVisible: true,
          },
        ]
    },


    {
      label: 'TEST== Department label ==TEST',
      description: 'orem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at dui.',
      path: 'test',
      isVisible: false,
      services:[
        {
          label: 'TEST== Service label ==TEST',
          path: 'test',
          form: 'test',
          subDepartment: 'test',
          description: 'Etiam dictum tincidunt diam. In convallis. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu. Fusce nibh. Fusce wisi.',
          isVisible: true,
        },
        {
          label: 'TEST2== Service label ==TEST2',
          path: 'test2',
          form: 'test2',
          subDepartment: 'test2',
          description: 'Nullam rhoncus aliquam metus. Nam libero tempore, cum soluta nobis est eligendi optio cumque',
          isVisible: true,
        }
      ]
    }
]
