<script setup>
const props = defineProps(['fluidProp'])
const appVersion = import.meta.env.VITE_APP_VERSION
</script>

<template>
  <v-footer ref="footer" class="flex-grow-0 bg-transparent border-opacity-25 border-t">
    <v-container :fluid="props.fluidProp">
      <v-row align="center">
        <!-- copyright year, rcx logo -->
        <v-col cols="auto" class="d-flex align-center">
          <span class="pr-2">© {{ new Date().getFullYear() }}</span>
          <v-icon tag="span" style="width: auto; height: 18px;">$RcxMuniLogo</v-icon>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="d-flex align-center">
          <v-icon tag="a" href="https://vuejs.org" target="_blank" class="px-2" style="width: auto; height: 18px;">$VueLogo</v-icon><span class="text-disabled">&</span><v-icon tag="a" href="https://vuetifyjs.com" target="_blank" class="px-2" style="width: auto; height: 18px;">$VuetifyLogo</v-icon>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          {{ appVersion }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">

</style>
