import { cs } from 'vuetify/locale'

export default {
  ...cs,
  language: {
    tooltip: 'Změnit jazyk',
    en: 'Anglicky',
    cs: 'Česky'
  },
  logout: {
    tooltip: 'Odhlásit'
  },
  appTitle: {
    title: 'OAF',
  },
  noDepartments: 'Oddělení nejsou definována.',
  noServices: 'Služby nejsou definována.',
  noForm: 'Formulář není definován.',
  noUser: 'Uživatel není přihlášen.',
  dashboard: 'Dashboard',
  pageNotFound: '404: Stránka nenalezena',
  formSendSuccess: 'Formulář byl úspěšně odeslán k dalšímu zpracování.',
  formLoadSuccess: 'Formulář byl úspěšně načten.',
  formValid: 'Formulář je vyplněn správně.',
  formNotValid: 'Prosím, zkontrolujte znovu všechna pole formuláře.',
  formLoad: 'Nahrát formulář',
  formSend: 'Poslat formulář',
  formUpdate: 'Upravit formulář',
  formValidate: 'Zkontrolovat formulář',
  formClearValidations: 'Smazat kontroly formuláře',
  formDataCleared: 'Data formuláře byla smzána.',
  formClearData: 'Smazat data',
  collapseAll: 'Zabalit vše',
  collapseAllGroups: 'Zabalit skupiny v sekci',
  expandAll: 'Rozbalit vše',
  expandAllGroups: 'Rozbalit skupiny v sekci',
  groupRemove: 'Odstranit skupinu',
  groupAdd: 'Přidat skupinu',
  formEditable: 'Editovatelný formulář',
  validationMessages: {
    required: 'Toto pole je vyžadováno.',
    email: 'Prosím použijte správnou emailovou adresu (např.: name.surname@company.com).',
    numeric: 'Prosím použijte pouze čísla.',
    phone: 'Prosím použijte správný formát telefoního čísla (např.: +420 456 785 236).'
  },
  groups: 'skupin',
  sections: 'sekcí',
  calendarActions: {
    ok: 'Ok',
    cancel: 'Zrušit',
  },
  // datePicker: {
  //   ok: 'CS Ok',
  //   cancel: 'CS Cancel',
  //   range: {
  //     title: 'CS Range title',
  //     header: 'CS Range header',
  //   },
  //   title: 'CS Title',
  //   header: 'CS Header',
  //   input: {
  //     placeholder: 'CS Input placehodler'
  //   }
  // }
}
