import { createRouter, createWebHistory } from 'vue-router'
import DepartmentView from './../views/DepartmentView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  base: import.meta.env.VITE_APP_PUBLICPATH,
  routes: [
    {
      path: '/',
      name: 'departments',
      component: DepartmentView
    },
    {
      path: '/departments/:department/services/:service',
      name: 'Service form',
      component: () => import('../views/ServiceView.vue'),
      // props: route => ({ department: route.params.department })
    },
    {
      name: 'PageNotFound',
      path: '/:pathMatch(.*)*',
      component: () => import('../components/PageNotFound.vue'),
    }
  ]
})

export default router
