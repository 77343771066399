<script setup>
const props = defineProps(['departmentsArray', 'fluidProp'])
const emit = defineEmits(['getAppBarHeight', 'setFluid', 'showSnack'])
</script>


<template>
  <v-sheet
    id="sheet"
    ref="sheet"
    class="d-flex flex-column overflow-y-auto"
    height="100%"
  >
    <v-container :fluid="props.fluidProp" v-if="props.departmentsArray.length">
      <v-row>
        <v-col cols="12" class="text-h5">
          Welcome to the RECETOX Research Infrastructure Open Access proposal online submission form.
        </v-col>
        <v-col cols="12">
          Please, note to fill in the completed application form at least 3 months before the beginning of your project.
          If you need help, please, contact Coordinator of RECETOX Research Infrastructure <a href="mailto:petra.ruzickova@recetox.muni.cz" class="text-primary">Dr. Petra Ruzickova</a>.
          <!--            petra.ruzickova@recetox.muni.cz-->
        </v-col>
      </v-row>

      <v-row v-masonry>
        <v-col cols="12" lg="6" md="6" sm="12" v-for="(department, index) in props.departmentsArray" :key="index">
          <v-card variant="elevated" elevation="2" rounded="0">
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-row no-gutters>
                  <v-col cols="12">
                    RCX
                  </v-col>
                  <v-col  cols="12" class="text-h5 text-primary">
                    {{ department.label }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="ma-0 pa-0" v-html="department.description"></p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="pa-0">
              <v-container fluid class="pa-0">
                <v-row no-gutters>
                  <v-col cols="12" v-for="(service, index) in department.services.filter(ser => ser.isVisible)" :key="index">
                    <v-card rounded="0" variant="text" class="pa-0">

                      <v-card-text class="pa-0" v-if="service.description">
                        <v-container fluid>
                          <v-row no-gutters>
                            <v-col  cols="12" class="text-h6">
                              {{ service.label }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <p class="ma-0 pa-0" v-html="service.description"></p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="pa-0">
                        <v-container fluid class="pt-0">
                          <v-row no-gutters>
                            <v-col cols="12" class="text-right">
                              <v-btn
                                variant="outlined"
                                elevation="2"
                                color="success"
                                rounded="0"
                                :to="{ path: `/departments/${department.path}/services/${service.path}`, query: { subDepartment: service.subDepartment } }"
                              >
                                <v-icon class="mr-2" size="16">mdi-format-list-text</v-icon> View form
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>

                      </v-card-actions>
                    </v-card>
                    <v-divider v-if="index < department.services.length - 1"></v-divider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container :fluid="props.fluidProp" class="fill-height" v-else>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" class="text-center">
          <v-alert
            border="start"
            border-color="tertiary"
          >
            {{ $vuetify.locale.t('$vuetify.noDepartments') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>


<style scoped lang="scss">
#sheet {
  background: rgb(var(--v-theme-background));
}
</style>
