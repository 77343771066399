<script setup>
import { ref, watch } from 'vue'
import { useTheme } from 'vuetify'
import { RouterView } from 'vue-router'
import AppBar from './components/AppBar.vue'
import FooterBar from './components/FooterBar.vue'
import { departments } from './../form-config/departments'
import emitter from './plugins/emitter'
import {useAppStore} from "./stores/app";
import {useThemeTransitionService} from "./services/themeTransitionService";

emitter.on('show-snack', showSnack)

const theme = useTheme()
const appStore = useAppStore()

const setDarkMode  = () =>  {
  const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
  prefersDarkScheme.matches ? theme.global.name.value = 'dark' : 'light'
}
setDarkMode()

const departmentsArray = departments.filter(dep => dep.isVisible)
const fluidProp = ref(false)
const snackbarText = ref('')
const snackbarColor = ref('')
const snackbarIco = ref('')
const snackbar = ref(false)
const snackbarButton = ref(false)
const snackbarTimeout = ref(4000)
const appBarHeight = ref(0)

function showSnack ({ text, color, ico, btn, timeout }) {
  snackbarText.value = text
  snackbarColor.value = color
  snackbarIco.value = ico
  snackbar.value = true
  snackbarButton.value = btn
  snackbarTimeout.value = timeout
}

appStore.changeAppLocale(localStorage.getItem('oaf-app-locale') || 'en')

function getAppBarHeight () {
  appBarHeight.value = document.getElementById('appBar').getBoundingClientRect().height
  // console.log(appBarHeight.value)
}

watch(theme.global.name, useThemeTransitionService)

</script>

<template>
  <v-app v-resize="getAppBarHeight">

    <v-snackbar
      v-model="snackbar"
      :color="`${snackbarColor}`"
      :timeout="snackbarTimeout ? snackbarTimeout : 7000"
      location="top"
      position="sticky"
      class="pa-0"
    >
      <v-container fluid>
        <v-row>
          <v-col v-html="snackbarText">
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-container fluid>
          <v-row>
            <v-col cols="auto">
              <v-btn
                v-if="snackbarButton"
                tile
                small
                color="success"
              >OK</v-btn>
              <v-icon v-if="snackbarIco" color="white" right>{{ snackbarIco }}</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-snackbar>

    <app-bar :fluid-prop="fluidProp" />

    <v-main :style="{ paddingTop: appBarHeight + 'px' }">
      <router-view
        @show-snack="showSnack($event)"
        @get-app-bar-height="getAppBarHeight"
        :departments-array="departmentsArray"
        :fluid-prop="fluidProp"
      />

    </v-main>

    <footer-bar
      :fluid-prop="fluidProp"
    />
  </v-app>
</template>

<style lang="scss">
@import "./settings/gradient.scss";

// Theme transition
.app-copy {
  position: fixed !important;
  z-index: -1 !important;
  pointer-events: none !important;
  contain: size style !important;
  overflow: clip !important;
}
.app-transition {
  --clip-size: 0;
  --clip-pos: 0 0;
  clip-path: circle(var(--clip-size) at var(--clip-pos));
  transition: clip-path .25s ease-out;
}
</style>
