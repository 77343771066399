<script setup>
import { useTheme } from 'vuetify'
// import AppBarUserIcon from './AppBarUserIcon'
import { useAppStore } from './../stores/app'

const theme = useTheme()
const appStore = useAppStore()
const props = defineProps(['fluidProp'])

const toggleTheme = () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
const appTitle = import.meta.env.VITE_APP_NAME
const languageMenu = [
  {
    title: 'en',
    value: 'en',
    isActive: true
  },
  {
    title: 'cs',
    value: 'cs',
    isActive: false
  }
]
const iconSize = 'small'
</script>

<template>
  <v-app-bar
    app
    class="gradient-background-primary text-white d-print-none"
    id="appBar"
    height="auto"
  >
    <v-container :fluid="props.fluidProp">
      <v-row align="center">

        <!-- App icon & title -->
        <v-col cols="auto">
          <router-link :to="{ path: '/' }" class="text-decoration-none text-white d-flex flex-row align-center">
            <v-icon icon="mdi-format-list-text" size="30" class="mr-2"></v-icon>
            <span class="font-weight-bold text-h5">{{ appTitle }}</span>
          </router-link>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto" class="pa-0 ma-0">
          <v-toolbar height="auto">
            <!-- Translate button -->
            <v-menu
              open-on-hover
              location="bottom"
              open-delay="0"
              transition="scale-transition"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="props"
                  color="white"
                  class="ma-0"
                >
                  <v-icon icon="mdi-translate" :size="iconSize" />
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in languageMenu"
                  :key="index"
                  link
                  :value="item.value"
                  @click="appStore.changeAppLocale(item.value)"
                  :active="item.value === appStore.appLocale"
                  :disabled="!item.isActive"
                >
                  <v-list-item-title>{{ $vuetify.locale.t(`$vuetify.language.${item.title}`) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Dark mode button -->
            <v-tooltip
              location="bottom"
            >
              <!--            :text="$vuetify.theme.current.dark ? 'Light mode' : 'Dark mode'"-->
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  color="white"
                  @click="toggleTheme"
                  class="ma-0"
                >
                  <v-icon :size="iconSize">{{ $vuetify.theme.current.dark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.theme.current.dark ? 'Light mode' : 'Dark mode' }}</span>
            </v-tooltip>

<!--            <v-divider-->
<!--              v-if="appStore.currentUser"-->
<!--              class="ma-3"-->
<!--              length="24"-->
<!--              thickness="2"-->
<!--              vertical-->
<!--              color="white"-->
<!--            ></v-divider>-->

<!--            &lt;!&ndash; Information about loged user &ndash;&gt;-->
<!--            <app-bar-user-icon v-if="appStore.currentUser"></app-bar-user-icon>-->

            <!-- Logout button -->
<!--            <v-btn-->
<!--              v-if="appStore.currentUser"-->
<!--              icon-->
<!--              variant="text"-->
<!--              color="white"-->
<!--              @click="logout"-->
<!--              class="ma-0"-->
<!--            >-->
<!--              <v-icon :size="iconSize">mdi-logout</v-icon>-->
<!--              <v-tooltip activator="parent" location="bottom">-->
<!--                {{ $vuetify.locale.t('$vuetify.logout.tooltip') }}-->
<!--              </v-tooltip>-->
<!--            </v-btn>-->
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>

</template>

<style scoped lang="scss">

</style>
