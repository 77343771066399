/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import router from './router'

// Composables
import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Plugins
import { registerPlugins } from './plugins'
import vuetify from './plugins/vuetify'
import { VueMasonryPlugin } from 'vue-masonry'
import VueRecaptcha from 'vue3-recaptcha-v2'

const app = createApp(App)

registerPlugins(app)

app
  .use(router)
  .use(vuetify)
  .use(createPinia())
  .use(VueMasonryPlugin)
  .use(VueRecaptcha, {
    siteKey: import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
  })
  .mount('#app')
